import { createSharedComposable, useLocalStorage } from '@vueuse/core'
import { type ZoneInfo, getZoneInfo } from '~/api'
import { DEFAULT_ZONECODE, ZONECODE } from '~/constants'
import useAuthStore from '~/stores/auth'
import { encodeObject } from '~/utils/tools'

export function useZoneCode() {
  const zoneCode = useLocalStorage(ZONECODE, DEFAULT_ZONECODE, { flush: 'sync' })
  return zoneCode
}

function _useZoneInfo() {
  const authStore = useAuthStore()
  const zoneInfo = ref<ZoneInfo>()

  init()
  async function init() {
    const [, res] = await getZoneInfo()
    zoneInfo.value = res?.data
  }

  function openCustomerService() {
    if (zoneInfo.value?.customerServiceLink) {
      const link = zoneInfo.value.customerServiceLink.startsWith('http') ? zoneInfo.value.customerServiceLink : `//${zoneInfo.value.customerServiceLink}`
      if (authStore.authed)
        location.href = `${link}?tp=hyc&t=${encodeObject(authStore.user!)}`
      else
        authStore.ensureLogin()
    }
    else if (zoneInfo.value?.customerServicePhone) {
      uni.makePhoneCall({ phoneNumber: zoneInfo.value.customerServicePhone })
    }
  }

  return {
    zoneInfo,
    hasCustomerService: computed(() => !!zoneInfo.value?.customerServiceLink || !!zoneInfo.value?.customerServicePhone),
    openCustomerService,
    index: computed(() => zoneInfo.value?.supportPageList.includes('PHONE_CHARGE') ? '/pages/index' : '/pages/iequity'),
  }
}

export const useZoneInfo = createSharedComposable(_useZoneInfo)

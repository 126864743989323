import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import type { UserInfo } from '~/api'
import * as api from '~/api'
import { USERINFO } from '~/constants'
import { createPath, getAppId, getCurrentPathWithSearch, getCurrentUrl, initiateWxAuth, isInWeixinBrowser, isSamePath, showAlert } from '~/utils/tools'

interface User {
  token: string
  info?: UserInfo
  expired: number
}

// 1.8h
const LOGIN_EXPIRED = 1.8 * 3600 * 1000

export default defineStore('auth', () => {
  const user = useStorage<User>(USERINFO)
  const authed = computed(() => !!user.value?.info)
  const launched = shallowRef(false)

  verifyAndGetUser()
  if (user.value) {
    try {
      Sentry.getCurrentScope()?.setUser({ id: user.value.info?.phone || 'unknown' })
    }
    catch {}
  }

  function verifyAndGetUser() {
    if (user.value && Date.now() < user.value.expired) {
      return user.value
    }
    else {
      setUser(null)
      return null
    }
  }

  function updateExpire() {
    if (user.value)
      user.value.expired = Date.now() + LOGIN_EXPIRED
  }

  function setUser(data: Partial<Omit<User, 'expired'>> | null) {
    user.value = data == null
      ? null
      : {
          ...user.value,
          ...data,
          ...(data.token ? { expired: Date.now() + LOGIN_EXPIRED } : null),
        } as User
  }

  async function ensureLogin(redirectUrl?: string) {
    let useReplace = false
    if (redirectUrl == null || isSamePath(location.href, redirectUrl)) {
      redirectUrl = getCurrentUrl()
      useReplace = true
    }
    const isLogin = !!verifyAndGetUser()
    const u = new URL(redirectUrl)
    if (!isLogin) {
      const appId = await getAppId()
      if (isInWeixinBrowser() && appId) {
        await initiateWxAuth(redirectUrl, appId)
      }
      else {
        const registerUrl = createPath('/pages/register', {
          redirect: u.pathname + u.search,
        })
        if (useReplace)
          router.replace(registerUrl)
        else
          router.push(registerUrl)
      }
      return false
    }
    else {
      return true
    }
  }

  /**
   * since we have the code, we call api to login
   * if the user has registered, we call loginPost to write credentials into local storage
   * otherwise, there are two cases:
   * 1. if the wx auth request is initiated by user, we go to register page
   * 2. if not, we just let it go
   */
  async function loginByWechat(code: string, isByUser = false) {
    setUser(null)
    const [err, res] = await api.login(code)
    if (err) {
      console.error(err.message)
      if (!import.meta.env.DEV) {
        await showAlert('登录失败')
        location.href = '/pages/index'
      }
    }
    else {
      if (res.data.zoneCode)
        useZoneCode().value = res.data.zoneCode
      if (!res.data.registerWechatMp) {
        if (isByUser) {
          await router.replace(createPath('/pages/register', {
            create: 1,
            token: res.data.access_token,
            redirect: getCurrentPathWithSearch(),
          }))
        }
      }
      else {
        const { access_token, ...others } = res.data
        await loginPost(access_token, others)
      }
    }
  }

  async function loginPost(token: string, info: UserInfo) {
    setUser({
      token,
      info,
    })
    Sentry.getCurrentScope()?.setUser({ id: info.phone || 'unknown' })
  }

  function logout() {
    setUser(null)
  }

  return {
    user,
    ensureLogin,
    loginByWechat,
    loginPost,
    logout,
    authed,
    launched,
    updateExpire,
  }
})

export const USERINFO = 'USERINFO'

export const WXAUTHURL = 'https://open.weixin.qq.com/connect/oauth2/authorize'
export const APPID = 'APPID'
export const ZONECODE = 'ZONECODE'
export const DEFAULT_APPID = 'wx9363860c863256ef'
export const DEFAULT_ZONECODE = import.meta.env.DEV || import.meta.env.MODE === 'staging' ? 'ZZHLIVuC' : 'ZZnvs9oP'
export const BUSINESS_LINE = 2088
export const DOMAIN = import.meta.env.MODE === 'staging' ? 'https://h5.test.bmhyc.com' : 'https://h5.bmhyc.com'

export const PAGES_NEED_AUTH = [
  '/pages/order',
  '/pages/order-detail',
  '/pages/coupon-list',
  '/pages/feedback',
  '/pages/phone-equity-detail',
  '/pages/phone-equity-more',
  '/pages/wallet',
  '/pages/withdraw',
]

import { hideLoading, omitEmptyValues, showAlert } from './tools'
import { DOMAIN } from '~/constants'
import useAuthStore from '~/stores/auth'

export interface Data {
  code: number
  msg?: string
}

export interface NormalData<T = unknown> extends Data {
  data: T
}

export interface ListData<Row = unknown> extends Data {
  total: number
  rows: Row[]
}

type ServerErrorType = 'Authentication' | 'Internal'

export class ServerError extends Error {
  constructor(public type: ServerErrorType, msg: string, options?: ErrorOptions) {
    super(msg, options)
  }
}

let hasRequestRelogin = false
async function relogin() {
  if (hasRequestRelogin)
    return
  hasRequestRelogin = true
  const authStore = useAuthStore()
  authStore.logout()
  hideLoading()
  await showAlert('登录超时，请重新登录')
  await authStore.ensureLogin(`${DOMAIN}${useZoneInfo().index.value}`)
}

interface RequestOptionsCustom {
  suppress?: boolean
}

type RequestOptions = UniApp.RequestOptions & RequestOptionsCustom

export async function request<T extends Data>(options: RequestOptions): Promise<[null, T] | [Error]> {
  const authStore = useAuthStore()
  const { url, header } = options
  const getAuthorization = () => {
    if (!header?.noToken && authStore.user?.token)
      return { Authorization: authStore.user.token }
    else
      return {}
  }
  try {
    const hheader = omitEmptyValues({
      ...getAuthorization(),
      ...header,
    })
    const res = await uni.request({
      ...options,
      url: url.startsWith('http') ? url : `${import.meta.env.VITE_APP_BASE_URL}${url}`,
      header: hheader,
    })

    const resData = res.data as T | null
    if (res.statusCode === 401 || (resData && [10002, 10003, 10004].includes(resData?.code))) {
      throw new ServerError('Authentication', 'No Authentication')
    }

    else {
      if (hheader.Authorization)
        authStore.updateExpire()

      if (res.statusCode < 200 || res.statusCode >= 300)
        throw new ServerError('Internal', 'Internal Server Error')

      else if (resData?.code !== 200)
        throw new Error(resData?.msg)

      else
        return [null, resData]
    }
  }
  catch (e) {
    const error = e as Error
    let serror: ServerError
    if (error instanceof ServerError)
      serror = error
    else
      serror = new ServerError('Internal', error.message)
    if (serror.type === 'Authentication' && !options.suppress)
      await relogin()

    return [serror]
  }
}

type RequestOptionsExtra = Omit<RequestOptions, 'url'>

export function get<T extends Data>(url: string, query?: RequestOptions['data'], options?: RequestOptionsExtra) {
  return request<T>({
    ...options,
    url,
    method: 'GET',
    data: query,
  })
}

export function post<T extends Data>(url: string, data: RequestOptions['data'], options?: RequestOptionsExtra) {
  return request<T>({
    ...options,
    url,
    method: 'POST',
    data,
  })
}

export function postX<T extends Data>(url: string, data: RequestOptions['data'], options?: RequestOptionsExtra) {
  return request<T>({
    ...options,
    url,
    method: 'POST',
    header: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

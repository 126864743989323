<script setup lang="ts">
import { onLaunch } from '@dcloudio/uni-app'
import wx from 'weixin-js-sdk'
import { until } from '@vueuse/core'
import { getAppId, getCurrentUrl, initJweixin, initiateWxAuth, isInWeixinBrowser, setAppId } from './utils/tools'
import useAuthStore from '~/stores/auth'

onLaunch(async (ctx) => {
  const appId = ctx?.query.state
  const zoneCode = ctx?.query.zoneCode
  const code = ctx?.query.code
  const isTriggeredByUser = !!ctx?.query.user
  const z = useZoneCode()
  const authStore = useAuthStore()

  if (appId)
    setAppId(appId)
  if (zoneCode) {
    if (z.value && z.value !== zoneCode) {
      authStore.logout()
      setAppId('')
    }
    z.value = zoneCode
  }

  if (!import.meta.env.DEV && isInWeixinBrowser())
    initJweixin(getCurrentUrl())

  if (code && !location.pathname.includes('cashier') && !location.pathname.includes('withdraw') && !authStore.authed)
    await authStore.loginByWechat(code, isTriggeredByUser)

  if (isInWeixinBrowser() && !code && (zoneCode || appId) && !authStore.authed && !location.pathname.startsWith('/pages/register')) {
    const _appId = appId || (await getAppId(true))
    if (_appId) {
      initiateWxAuth(getCurrentUrl(), _appId, false)
      return
    }
  }

  const { zoneInfo } = useZoneInfo()
  await until(zoneInfo).toBeTruthy()

  const isIndex = location.pathname === '/' || location.pathname === '/pages/index'
  if (isIndex && !zoneInfo.value?.supportPageList.includes('PHONE_CHARGE'))
    router.replace('/pages/iequity')

  watch(zoneInfo, (v) => {
    if (v?.supportPageList.includes('PHONE_CHARGE'))
      document.body.classList.add('has-phone')
    if (v?.supportPageList.includes('VIDEO_MEMBER'))
      document.body.classList.add('has-video')

    setTimeout(() => {
      document.body.classList.remove('tab-pending')
    }, 200)
  }, { immediate: true })

  authStore.launched = true

  if (!import.meta.env.DEV) {
    wx.ready(() => {
      // @ts-expect-error type is incomplete
      wx.hideAllNonBaseMenuItem({
        success: () => {
          wx.showMenuItems({
            menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline'],
          })
        },
      })
    })
  }
})
</script>

import wx from 'weixin-js-sdk'
import { useSessionStorage } from '@vueuse/core'
import { hideLoading, showLoading } from './loading'
import { ServerError } from './request'
import type { PreparePay } from '~/api'
import * as api from '~/api'
import { APPID, DOMAIN, WXAUTHURL } from '~/constants'

export {
  showLoading,
  hideLoading,
}

export function showAlert(payload = {}) {
  return promisify(uni.showModal)({
    title: '提示',
    showCancel: false,
    ...(typeof payload === 'string'
      ? {
          content: payload,
        }
      : payload),
  })
}

export function showConfirm(payload = {}) {
  return promisify(uni.showModal)({
    title: '提示',
    showCancel: true,
    ...(typeof payload === 'string'
      ? {
          content: payload,
        }
      : payload),
  }) as Promise<{ confirm: boolean }>
}

export function showToast(payload = {}) {
  uni.showToast({
    icon: 'none',
    ...(typeof payload === 'string'
      ? {
          title: payload,
        }
      : payload),
  })
}

/**
 * 将 success fail complete 的接口转化为 promise 形式
 * @template T
 * @param {T} api
 * @return {(...x: Parameters<T>) => Promise} promisified api
 */
export function promisify(api: any, ctx = uni) {
  return function promisified(options: any, ...others: any) {
    return new Promise((resolve, reject) => {
      const oldApi = api.bind(ctx)

      oldApi({
        ...options,
        success: resolve,
        fail: reject,
      }, ...others)
    })
  }
}

export function omitEmptyValues(obj: Record<string, unknown>): Record<string, Exclude<unknown, null | undefined>> {
  return Object.keys(obj)
    .map(k => [k, obj[k]] as const)
    .filter(([_k, v]) => v != null)
    .reduce((acc, [k, v]) => {
      return {
        ...acc,
        [k]: v,
      }
    }, {})
}

export function createUrl(url: string, extraParams?: Record<string, string | number | null | undefined>, hash?: string) {
  const u = new URL(url)
  for (const key in extraParams) {
    if (extraParams[key] != null)
      u.searchParams.set(key, extraParams[key]?.toString() || '')
  }
  u.searchParams.delete('code')
  if (hash)
    u.hash = `#${hash}`.replace('##', '#')
  return u.toString()
}

export function createPath(url: string, extraParams?: Record<string, string | number | null | undefined>) {
  const u = new URL(`${DOMAIN}${url}`)
  for (const key in extraParams) {
    if (extraParams[key] != null)
      u.searchParams.set(key, extraParams[key]?.toString() || '')
  }
  return u.pathname + u.search
}

export function getCurrentPath() {
  const url = new URL(location.href)
  return url.pathname
}

export function getCurrentPathWithSearch() {
  const url = new URL(location.href)
  return url.pathname + url.search
}

export function getCurrentUrl() {
  return location.href
}

export function last<T>(arr: T[]) {
  return arr[arr.length - 1]
}

export function createControlledPromise() {
  let resolve: (value: unknown) => void
  const callback = () => {
    resolve?.(null)
  }
  const promise = new Promise((_resolve) => {
    resolve = _resolve
  })

  return [promise, callback] as const
}

interface SplitDecimalResult {
  decimalPointBefore: string
  decimalPointAfter: string
}

/**
 * 将数字以小数点分割，以表现不同样式
 * @param num 数字
 * @return {SplitDecimalResult} 格式化后的对象
 */
export function splitDecimal(num: number): SplitDecimalResult {
  const numberToString = String(num)
  const [before, after] = numberToString.split('.')
  return {
    decimalPointBefore: before,
    decimalPointAfter: after || '00',
  }
}

export async function initJweixin(url: string) {
  const appId = await getAppId()

  if (!appId)
    return
  const [err, res] = await api.getJssdkConfig(url, appId)

  if (!err) {
    wx.config({
      debug: false,
      appId,
      timestamp: +res.data.timestamp,
      nonceStr: res.data.nonceStr,
      signature: res.data.signature,
      jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'hideAllNonBaseMenuItem', 'showMenuItems'],
    })
  }
}

export async function getAppId(forceFromZone = false) {
  const appId = useSessionStorage(APPID, '')
  if (!forceFromZone && appId.value) {
    return appId.value
  }
  else {
    const zoneCode = useZoneCode()
    const [_err, res] = await api.getAppIdByZoneCode(zoneCode.value)
    return res?.data
  }
}

export function setAppId(v: string) {
  const appId = useSessionStorage(APPID, '', { flush: 'sync' })
  appId.value = v
}

export type PayResult = 'SUCCESS' | 'CANCEL' | 'FAIL'

export function requestPay(p: PreparePay): Promise<PayResult> {
  return new Promise((resolve) => {
    if ('WeixinJSBridge' in window) {
      window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
        appId: p.appId,
        timeStamp: p.timeStamp,
        nonceStr: p.nonceStr,
        package: p.packageStr,
        signType: p.signType,
        paySign: p.paySign,
      }, (res: any) => {
        switch (res.err_msg) {
          case 'get_brand_wcpay_request:ok':
            resolve('SUCCESS')
            break
          case 'get_brand_wcpay_request:cancel':
            resolve('CANCEL')
            break
          case 'get_brand_wcpay_request:fail':
            resolve('FAIL')
            break
        }
      })
    }
    else {
      resolve('CANCEL')
    }
  })
}

export function getMaskPhone(phone?: string) {
  if (!phone)
    return phone
  return `${phone.slice(0, 3)}****${phone.slice(-4)}`
}

export function isInWeixinBrowser() {
  return window.navigator.userAgent.toLowerCase().includes('micromessenger')
}

export function withAuthErrorHandled(err: Error, callback: () => void) {
  if (!(err instanceof ServerError && err.type === 'Authentication'))
    callback()
}

export type Platform = 'iOS' | 'Android' | 'Wap'
export function getPlatform(): Platform {
  const userAgent = navigator.userAgent

  if (/android/i.test(userAgent))
    return 'Android'

  if (/iPad|iPhone|iPod/.test(userAgent))
    return 'iOS'

  return 'Wap'
}

/**
 * initiate a wx auth request
 */
export function initiateWxAuth(redirectUrl: string, appId: string, isUser = true) {
  // flag the request is initiated by user
  const r = createUrl(redirectUrl, { user: isUser ? 1 : null })
  const rr = new URL(r)
  rr.searchParams.delete('state')
  rr.searchParams.delete('appid')
  rr.searchParams.delete('code')

  const url = createUrl(WXAUTHURL, {
    appid: appId,
    redirect_uri: rr.toString(),
    response_type: 'code',
    scope: 'snsapi_base',
    state: appId,
    ...(import.meta.env.MODE === 'staging' ? {} : { component_appid: import.meta.env.VITE_APP_COMPONENT_APPID }),
  }, '#wechat_redirect')

  location.href = url

  // prevent anything else from executing when in production
  return import.meta.env.DEV
    ? new Promise(resolve => setTimeout(resolve, 1500))
    : new Promise((resolve) => {
      if (false)
        resolve(undefined)
    })
}

export function handleGoodsNavigate(item: api.ProductItem) {
  if (item.h5Url)
    location.href = item.h5Url
  else
    router.push(`/pages/goods-detail?id=${item.id}`)
}

export function isSamePath(url1: string, url2: string) {
  const u1 = new URL(url1)
  const u2 = new URL(url2)
  return u1.pathname === u2.pathname
}

export function encodeObject(obj: object) {
  return btoa(encodeURIComponent(JSON.stringify(obj)))
}

export function decodeObject(str: string) {
  return JSON.parse(decodeURIComponent(atob(str)))
}

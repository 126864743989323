import { BUSINESS_LINE } from '~/constants'
import type { EquityReceiveStatusDict, chargeAccountTypeDict, couponStatus, equityTypeDict, orderStatusDict, userOrderSourceDict, walletLogStatus } from '~/utils/dict'
import { type ListData, type NormalData, get, post, postX } from '~/utils/request'
import { type Platform, getAppId, isInWeixinBrowser } from '~/utils/tools'

export interface UserInfo {
  phone?: string
}

export type EquityType = (typeof equityTypeDict)[number]['value']

export interface EquityProduct {
  productId: number
  productName: string
  imgUrl: string
  originAmount?: number
  receiveStatus: (typeof EquityReceiveStatusDict)[number]['value']
  chargeAccountTypeList: (typeof chargeAccountTypeDict)[number]['value'][]
}

export interface SpecificationItem {
  id: number
  specification: string
  originAmount: number
  amount: number
}

export interface Equity {
  id: number
  introduction: string
  productList: EquityProduct[]
  specificationList: SpecificationItem[]
  detailPageProductUrlList: string[]
  memberInfo: {
    member: boolean
    phone?: string
    chargeQQ?: string
    chargeWechat?: string
    expireTime?: string
    receiveFinish: 'YES' | 'NO'
  }
}

// 权益详情
export function getEquityDetail(type: EquityType) {
  return get<NormalData<Equity>>('/market/app/equity/getEquity', { type, zoneCode: useZoneCode().value })
}

export async function getJssdkConfig(url: string, appId: string) {
  return get<NormalData<{
    timestamp: string
    nonceStr: string
    signature: string
  }>>('/message/mp/getJsapiSignature', {
    url,
    appId,
  }, {
    header: {
      Authorization: '',
    },
  })
}

export function getCaptcha() {
  return get<{ code: number, img: string, uuid: string }>('/code', {}, {
    header: {
      Authorization: '',
    },
  })
}

export async function getAppIdByZoneCode(zoneCode: string) {
  return get<NormalData<string>>('/user/getMpAppId', { id: zoneCode })
}

export async function login(code: string) {
  return post<NormalData<{
    access_token: string
    registerWechatMp: boolean
    zoneCode: string
  } & UserInfo>>('/user/login/wechat', {
    businessLine: BUSINESS_LINE,
    appId: await getAppId(),
    loginSource: 'WECHAT_MP',
    code,
  }, {
    header: {
      Authorization: '',
    },
  })
}

export function loginExternal(payload: {
  phone: string
  code: string
  captcha: string
  uuid: string
  zoneCode: string
}) {
  return post<NormalData<{
    access_token: string
    zoneCode: string
  } & UserInfo>>('/user/login/captcha', {
    ...payload,
    loginSource: 'H5',
    businessLine: BUSINESS_LINE,
  }, {
    header: {
      Authorization: '',
    },
  })
}

export function register(payload: {
  phone: string
  code: string
  token: string
}) {
  return post<NormalData<{
    access_token: string
    zoneCode: string
  } & UserInfo>>('/user/register/wechat', {
    businessLine: BUSINESS_LINE,
    registerSource: 'WECHAT_MP',
    ...payload,
  }, {
    header: {
      Authorization: '',
    },
  })
}

export function sendRegisterMsg(payload: { phone: string, token: string }) {
  return post('/user/code/sendWithoutCaptcha', {
    captchaType: 'REGISTER',
    zoneCode: useZoneCode().value,
    ...payload,
  }, {
    header: {
      Authorization: '',
    },
  })
}

export function sendLoginMsg(payload: {
  phone: string
  captcha: string
  uuid: string
}) {
  return post('/user/code/send', {
    ...payload,
    captchaType: 'LOGIN',
    zoneCode: useZoneCode().value,
  }, {
    header: {
      Authorization: '',
    },
  })
}

export function sendValidateMsg(payload: {
  captcha: string
  uuid: string
}) {
  return post('/user/code/sendSms', payload)
}

// 权益服务协议
export function getEquityAgreement(type: string) {
  return get<NormalData<string>>('/market/app/equity/getEquityServiceAgreement', { type, zoneCode: useZoneCode().value }, {
    header: {
      Authorization: '',
    },
  })
}

export interface EquityOrderAndPayPayload {
  equityId: number
  equityConfigSpecificationId: number
  payChannel: PayChannel
  wechatH5SceneType?: Platform
  bizMerchantId: number
  merchantNo: string
  appId: string
  code?: string
}

// 权益下单并支付
export function saveEquityOrderThenPay(payload: EquityOrderAndPayPayload) {
  return post<NormalData<PreparePay>>('/market/app/order/saveEquityOrderAndPrePay', payload)
}

export type PayChannel = 'WECHAT_JSAPI' | 'WECHAT_H5'

export interface PreparePay {
  orderNo: string
  appId: number
  timeStamp: string
  nonceStr: string
  /** JSAPI下单接口返回的prepay_id参数值，提交格式如：prepay_id= */
  packageStr: string
  signType: string
  paySign: string
  h5Url?: string
  codeUrl?: string
  needPay?: 'YES' | 'NO'
  payAmount: string
}

export interface OrderPayPayload {
  orderNo: string
  payChannel: PayChannel
  wechatH5SceneType?: Platform
  bizMerchantId: number
  merchantNo: string
  appId: string
  code?: string
}

// 订单支付
export function orderPay(category: GoodsCategory, payload: OrderPayPayload) {
  return category === 'DIRECT' ? directOrderPay(payload) : phoneOrderPay(payload)
}

export function directOrderPay(payload: OrderPayPayload) {
  return post<NormalData<PreparePay>>('/market/app/order/prePay', payload)
}

export function phoneOrderPay(payload: OrderPayPayload) {
  return post<NormalData<PreparePay>>('/market/app/phoneOrder/prePay', payload)
}

export interface ChargeAccount {
  chargeAccountType?: (typeof chargeAccountTypeDict)[number]['value']
  chargeAccount?: string
}

// 月月N选1 领取会员
export function receiveEquityProduct(payload: {
  productId: number
} & ChargeAccount) {
  return postX('/market/app/order/receiveEquityProduct', payload)
}

interface ProductItemBase {
  id: number
  name: string
  bannerUrl: string
  originAmount: number
  amount: number
  h5Url?: string
}

export interface DirectProductItem extends ProductItemBase {
  category: 'DIRECT'
}

export interface PhoneProductItem extends ProductItemBase {
  category: 'PHONE'
  reduceAmount: number
}

export type ProductItem = DirectProductItem | PhoneProductItem

// 商品列表-分页
export function getProductList<C extends GoodsCategory>(params: {
  keyword?: string
  pageNum: number
  category: C
}) {
  return get<ListData<C extends 'PHONE' ? PhoneProductItem : DirectProductItem>>('/market/app/product/listProduct', { ...params, pageSize: 10, zoneCode: useZoneCode().value })
}

export interface ProductDetail {
  id: number
  name: string
  specification: string
  bannerUrl: string
  originAmount: number
  amount: number
  introduction: string
  detail: string
  category: GoodsCategory
  chargeAccountTypeList: (typeof chargeAccountTypeDict)[number]['value'][]
}

// 商品详情
export function getProductDetail(id: string) {
  return get<NormalData<ProductDetail>>('/market/app/product/getProduct', { id })
}

interface ProductOrderPayload {
  productId: number
  chargeAccount: string
  chargeAccountType: (typeof chargeAccountTypeDict)[number]['value']
}

export interface ProductOrderAndPayPayload extends ProductOrderPayload {
  payChannel: PayChannel
  wechatH5SceneType?: Platform
  couponOrderNoList?: string[]
  bizMerchantId: number
  merchantNo: string
  appId: string
  code?: string
}

// 商品下单并支付
export function saveProductOrderThenPay(category: GoodsCategory, payload: ProductOrderAndPayPayload) {
  if (category === 'DIRECT')
    return saveDirectProductOrderThenPay(payload)
  else
    return savePhoneProductOrderThenPay(payload)
}

export function saveDirectProductOrderThenPay(payload: ProductOrderAndPayPayload) {
  return post<NormalData<PreparePay>>('/market/app/order/saveProductOrderAndPrePay', payload)
}

export function savePhoneProductOrderThenPay(payload: ProductOrderAndPayPayload) {
  return post<NormalData<PreparePay>>('/market/app/phoneOrder/saveProductOrderAndPrePay', payload)
}

// 查询话费商品下单时可选优惠券
export function getPhoneProductCoupons(productId: number) {
  return get<NormalData<Coupon[]>>('/market/app/coupon/ListMyProductUse', { productId })
}

export type OrderStatus = (typeof orderStatusDict)[number]['value']
export interface OrderItemBase {
  id: number
  orderNo: string
  targetName: string
  targetSpecification: string
  targetImgUrl: string
  chargeAccount: string
  orderAmount: number
  payAmount: number
  status: OrderStatus
  gmtCreate: string
  category: GoodsCategory
  sourceType: (typeof userOrderSourceDict)[number]['value']
  productStatus: 'UP' | 'DOWN'
}

export interface OrderItemProduct extends OrderItemBase {
  targetType: 'PRODUCT'
  targetId: number
}

export interface OrderItemEquity extends OrderItemBase {
  targetType: 'EQUITY'
  equityType: EquityType
}

export type OrderItem = OrderItemProduct | OrderItemEquity

export function getOrderList(query: {
  pageNum: number
  status: OrderStatus | ''
}) {
  return get<ListData<OrderItem>>('/market/app/order/listOrder', {
    ...query,
    pageSize: 20,
  })
}

export type OrderDetail = OrderItem & { payExpireTime: string }

export function getDirectOrderDetail(orderNo: string) {
  return get<NormalData<OrderDetail>>('/market/app/order/getOrder', { orderNo })
}

export function getPhoneOrderDetail(orderNo: string) {
  return get<NormalData<OrderDetail>>('/market/app/phoneOrder/getOrder', { orderNo })
}

export function getOrderDetail(category: GoodsCategory, orderNo: string) {
  return category === 'PHONE' ? getPhoneOrderDetail(orderNo) : getDirectOrderDetail(orderNo)
}

export function cancelDirectOrder(orderNo: string) {
  return postX('/market/app/order/cancelOrder', { orderNo })
}
export function cancelPhoneOrder(orderNo: string) {
  return postX('/market/app/phoneOrder/cancelOrder', { orderNo })
}

export function cancelOrder(category: GoodsCategory, orderNo: string) {
  return category === 'DIRECT' ? cancelDirectOrder(orderNo) : cancelPhoneOrder(orderNo)
}

export interface LinkInfo {
  status: 'INIT' | 'USED'
  mainOrderNo: string
  targetInfo: string
  phone: string
  targetType: 'PRODUCT' | 'EQUITY'
  orderCreateTime: string
  chargeAccountTypeList: (typeof chargeAccountTypeDict)[number]['value'][] | null
  zoneCode: string
  mpName?: string
  mpPicUrl?: string
}

// 第三方充值 查询链接信息
export function getLinkInfo(secret: string) {
  return get<NormalData<LinkInfo>>(`/market/app/link/getLinkInfo/${secret}`)
}

// 第三方充值 获取短信验证码
export function sendRechargeMsg(payload: { phone: string, linkSecret: string, zoneCode?: string }) {
  return post('/user/code/sendWithoutCaptcha', {
    captchaType: 'LOGIN',
    ...payload,
    zoneCode: payload.zoneCode || useZoneCode().value,
  })
}

// 第三方充值 提交
export function recharge(payload: { phone: string, linkSecret: string, code: string }) {
  return post<NormalData<{
    accessToken: string
    registerWechatMp: boolean
    chargeAccountType?: (typeof chargeAccountTypeDict)[number]['value']
  } & UserInfo>>('/market/app/link/linkRecharge', payload)
}

// 查询隐私协议
export function getAgreement(type: string) {
  return get<NormalData<{ content: string }>>('/mis/contract/queryByType', { type })
}

export interface OrderStat { initCount: number, paySuccessCount: number }

// 个人中心订单数量
export function getOrderStat() {
  return get<NormalData<OrderStat>>('/market/app/order/getOrderCount', {})
}

// 服务器时间
export function getServerTime() {
  return get<NormalData<{ timestamp: number }>>('/info')
}

// 退出登录
export function logout() {
  return postX<NormalData>('/user/app/logout', {})
}

// feedback
export function feedback(payload: {
  type: string
  content: string
}) {
  return post('/mis/feedback/wxWebhook', payload)
}

export type AdPosition = 'CHARGE'

export type AdContentType = 'H5' | 'RICH_TEXT'

export interface Ad {
  id: number
  pictureUrl: string
  contentType: AdContentType
  content: string
}

// advertisement
export function getAds(position: AdPosition) {
  return get<NormalData<Ad[]>>('/market/app/advertise/listAdvertise', { position, zoneCode: useZoneCode().value })
}

export interface PayOptions {
  allowPay: 'YES' | 'NO'
  bizMerchantId: number
  merchantNo: string
  appId: string
}

export function getPayOptions() {
  return get<NormalData<PayOptions>>('/market/app/order/getPrePayParam', {
    payChannel: isInWeixinBrowser() ? 'WECHAT_JSAPI' : 'WECHAT_H5',
  })
}

export interface WithdrawOptions {
  allowTakeOut: 'YES' | 'NO'
  bizMerchantId: number
  merchantNo: string
  appId: string
}

export function getWithdrawOptions() {
  return get<NormalData<WithdrawOptions>>('/trade/cashBalance/getTakeOutParam', {
    merchantBizSystem: 'MARKET',
  })
}

export interface ZoneInfo {
  id: string
  zoneCode: string
  showMp: string
  mpName: string
  mpPicUrl: string
  customerServiceLink?: string
  customerServicePhone?: string
  allowNoPhoneEquityUser: 'YES' | 'NO'
  supportPageList: ('PHONE_CHARGE' | 'VIDEO_MEMBER')[]
}

export function getZoneInfo() {
  return get<NormalData<ZoneInfo>>('/market/app/zoneConfig/getZoneConfig', { zoneCode: useZoneCode().value })
}

export interface MineInfo {
  zoneId: string
  userId: string
  phone: string
  monthN1EquityMember: 'YES' | 'NO'
  phoneEquityMember: 'YES' | 'NO'
  monthN1EquityEndTime: string
  phoneEquityEndTime: string
}

export function getMineInfo() {
  return get<NormalData<MineInfo>>('/market/app/user/getMyInfo')
}

export type CouponStatus = (typeof couponStatus)[number]['value']

interface CouponQueryParams {
  statusList?: readonly CouponStatus[]
}

export interface Coupon {
  id: number
  name: string
  couponOrderNo: string
  status: CouponStatus
  amount: number
  limitMinAmount?: string
  limitMaxAmount?: string
  expireTime?: string
  allowTogether: 'YES' | 'NO'
}

export function getCouponList(params: CouponQueryParams) {
  return get<NormalData<Coupon[]>>('/market/app/coupon/listMy', params)
}

export function receiveCoupon(couponOrderNo: string) {
  return postX('/market/app/coupon/receive', { couponOrderNo })
}

export interface PhoneEquityItem {
  targetType: PhoneEquityType
  targetName: string
  amount: number
  receiveStatus: PhoneEquityReceiveStatus
  limitMinAmount?: string
  limitMaxAmount?: string
  expiredTime?: string
  targetId: string
  receiveTime?: string
  receiveTimeFrom?: string
  receiveTimeTo?: string
  couponStatus?: CouponStatus
}

export interface PhoneEquityStat {
  currentMonthEquity: PhoneEquityItem
  canReceiveCount: number
}

export function getPhoneEquityStat() {
  return get<NormalData<PhoneEquityStat>>('/market/app/phoneEquity/getEquity')
}

export function getPhoneEquityMore() {
  return get<NormalData<{
    canReceiveList: PhoneEquityItem[]
    receivedList: PhoneEquityItem[]
  }>>('/market/app/phoneEquity/getEquityReceive')
}

export interface PhoneEquityDetail {
  currentMonthEquity: PhoneEquityItem
  nextMonthEquity: PhoneEquityItem
  yetReceiveCount: number
  yetReceiveAmount: number
  remainReceiveCount: number
  remainReceiveAmount: number
  receiveDateList: number[]
}

export function getPhoneEquityDetail() {
  return get<NormalData<PhoneEquityDetail>>('/market/app/phoneEquity/getEquityDetail')
}

export interface Wallet {
  userId: string
  balance: string
  takeOutEnoughAmountOffset: string
  availableBalance: string
  takeOutEnoughAmount: string
  allowTakeOut: boolean
}

export function getWallet() {
  return get<NormalData<Wallet>>('/trade/cashBalance/getMyCashBalance')
}

export interface WalletLog {
  userId: string
  type: 'IN' | 'OUT'
  sourceBizType: string
  sourceBizTypeStr: string
  sourceOrderNo: string
  amount: string
  gmtCreate: string
  status: (typeof walletLogStatus)[number]['value']
}

export function getWalletLogs(pageNum: number) {
  return get<ListData<WalletLog>>('/trade/cashBalance/listMyCashBalanceLog', { pageNum, pageSize: 10 })
}

export function getLoginable(params: {
  phone: string
  equityType: 'MONTH_N1' | 'PHONE'
}) {
  return get<NormalData<boolean>>('/market/app/user/hasEquity', {
    ...params,
    zoneCode: useZoneCode().value,
  })
}

export function withdrawToWx(payload: {
  amount: string
  bizMerchantId: number
  merchantNo: string
  appId: string
  code: string
}) {
  return post<NormalData>('/trade/cashBalance/takeOut', { ...payload, merchantBizSystem: 'MARKET' })
}

export function getMaxUnusedCoupon() {
  return get<NormalData<Coupon>>('/market/app/coupon/getMaxAmountCoupon')
}

type PhoneOnlineValidateWay = 'UP_SMS' | 'DOWN_SMS'

type LoginType = 'PHONE_VERIFY_CODE' | 'USERNAME_PASSWORD' | 'PHONE_PASSWORD' | 'EMAIL_PASSWORD' | 'EMAIL_VERIFY_CODE' | 'WECHAT_QR' | 'WECHAT_APPLET' | 'WECHAT_MP' | 'ALIPAY_APPLET' | 'THIRD' | 'DING_TALK_QR'

export interface VerifyState {
  onNetVerifyWay: PhoneOnlineValidateWay
  myLoginType: LoginType
}

export function getPhoneOnlineState() {
  return get<NormalData<VerifyState>>('/market/app/phoneOrder/getReceiveEquityConfig')
}

export function receivePhoneEquity(productId: number, code?: string) {
  return post<NormalData>('/market/app/phoneOrder/receiveEquityProduct', {
    productId,
    code,
  })
}

export function activatePhoneEquity() {
  return post<NormalData>('/market/app/phoneOrder/activeEquityOrder', {})
}
